import {lazy, FC, Suspense, useEffect, useState} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../marketConnect/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../marketConnect/assets/ts/_utils'
import {WithChildren} from '../../marketConnect/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import ConnectedPlatforms from '../pages/listing/ConnectedPlatforms'
import Platforms from 'src/app/pages/listing/Platforms'
import TemplateAndPricing from 'src/app/pages/template-and-pricing/TemplateAndPricing'
import AllProducts from '../pages/products/AllProducts'
import data from 'src/@dummyData/data.json';
import Orders from '../pages/orders/Orders'
import Inventory from '../pages/inventory/Inventory'
import ProductsTable from '../pages/listing/components/listing-products/ProductsTable'
import OrderDetails from '../pages/orders/OrderDetails'
import AccountSetting from '../pages/AccountSetting'
import ProductDetail from '../pages/product-detail/ProductDetail'
import UserInvite from '../pages/user-invite/UserInvites'
import UserInvites from '../pages/user-invite/UserInvites'
import TemplateAndPricingCopy from '../pages/template-and-pricing/TemplateAndPricingCopy'
import CreateProduct from '../pages/create-product/CreateProduct'
import UpdateProduct from '../pages/update-Product/UpdateProduct'
import ProductVariantDetail from '../pages/product-detail/productVariantDetail'
import ManageCustomers from '../pages/admin-panel/ManageCustomers'
import Report from '../pages/admin-panel/Report'
import ManageCustomerscopy from '../pages/admin-panel/ManageCustomerscopy'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import { SuperAdminRoutes } from './SuperAdminRoutes'
import { fetchUserAccountDetailsAsync } from 'src/redux/actions/accountActions'
import { accessSessionTokenKeyName } from 'src/utils/constants'
import CSVDownload from '../pages/download/CSVDownload'
import PlatformSetting from '../pages/listing/PlatformSetting/PlatformSetting'
import PrimaryPlatformSetting from '../pages/listing/PlatformSetting/PrimaryPlatformSetting'
import Vendors from '../pages/vendor/Vendors'
import AddUpdateVendorData from '../pages/vendor/AddUpdateVendorData'
import DynamicPlatformSetting from '../pages/listing/DynamicPlatformSetting'
import DynamicPlatformSettingCopy from '../pages/listing/DynamicPlatformSettingCopy'
import Pricing from '../pages/pricing-page/Pricing'
import Dashboard from '../pages/dashboard/Dashboard'



// import CreateProduct from '../pages/create-product/createProduct'


const PrivateRoutes = () => {

  const {  fetchedUserAccountData } = useAppSelector(state=>state.userAccountData)
  const [loading, setLoading] = useState(true)
  const dispatch = useAppDispatch()

  useEffect(() => {
    // Dispatch the fetch user data action on component mount
    dispatch(fetchUserAccountDetailsAsync())
      .then((response) => {
        console.log('User account data: in prr', response.payload)
       setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching user data: in prr', error)
        setLoading(false)
      })
  }, [localStorage.getItem(accessSessionTokenKeyName)])

  
  if(loading ) return <Loader/>
  
  return (
    <Routes>
       { fetchedUserAccountData?.user_type === 'USER' || fetchedUserAccountData===null  ? 
         <Route element={<MasterLayout />}>
              {/* Redirect to Dashboard after success login/registartion */}
              <Route path='/*' element={<Navigate to='/dashboard' />} />
              <Route path='auth/*' element={<Navigate to='/dashboard' />} />
              {/* Pages */}
              <Route path='dashboard' element={<DashboardWrapper />} />
              <Route path='test-dashboard' element={<Dashboard />} />

              <Route path='/platforms' element={<Platforms />} />
              <Route path='/listing-channels' element={<ConnectedPlatforms />} />
              <Route path='/listing-channels/:selectedPlatformId' element={<ConnectedPlatforms />} />
              <Route path='/platform-setting/:selectedPlatformId' element={<PlatformSetting/>}/>
              <Route path='/primary-platform-setting/:selectedPlatformId' element={<PrimaryPlatformSetting/>}/>
              <Route path='/template-and-pricing' element={<TemplateAndPricing />} />
              <Route path='/products-all' element={<AllProducts/>}  />
              <Route path='/orders-all' element={<Orders/>}  />
              <Route path='/inventory-all' element={<Inventory/>}  />
              <Route path='/listing-product-table/:id' element={<ProductsTable/>} />
              <Route path='/sales/details/:orderId' element={<OrderDetails/>} />
              <Route path='/product-variant-detail/:variantId/platform/:platformId/product/:productId' element={<ProductDetail/>} />
              <Route path='/product-linked-variant-detail/:variantId/platform/:platformId/product/:productVariantId' element={<ProductVariantDetail/>} />
              <Route path='/account/settings' element={<AccountSetting/>} />
              <Route path='/user-invite' element={<UserInvites/>}/>
              <Route path='/create-product' element={<CreateProduct/>}/>
              <Route path='/update-product' element={<UpdateProduct/>}/>
              <Route path='/manage-customers' element={<ManageCustomers/>}/>
              <Route path='/manage-customers-copy' element={<ManageCustomerscopy/>}/>
              <Route path='/admin-reports' element={<Report/>}/>
              <Route path='/products-files' element={<CSVDownload/>}/>
              <Route path='/vendors' element={<Vendors/>}/>
              <Route path='/edit-vendor/:vendorId' element={<AddUpdateVendorData  />}/>
              <Route path='/platform-setting-new/:availablePlatformId/:selectedPlatformId' element={<DynamicPlatformSetting/>} />
              <Route path='/pricing/:platformId' element={<Pricing/>}/>




              {/* Page Not Found */}
              <Route path='*' element={<Navigate to='/error/404' />} />
          </Route> :
          <Route element={<MasterLayout />}>
            <Route path='/*' element={<SuperAdminRoutes/>} />
          </Route>         
      }
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}