import React, {useEffect, useState} from 'react'
import {KTSVG} from 'src/marketConnect/helpers'
import AlertModal from 'src/utils/modals/AlertModal'
import ConnectPlatformStrip from './components/ConnectPlatformStrip'
import ConnectedPlatformSettingModal from './components/ConnectedPlatformSettingModal'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Spinner from 'react-bootstrap/Spinner'

import {
  createPlatformSettingAsync,
  deletePlatformDataAsync,
  fetchConnectedPlatformAsync,
  retrivePlatformDataAsync,
  shopifyappUninstallAsync,
} from 'src/redux/actions/platformActions'
import {PlatformDataType, PlatformSettingsDataType} from 'src/interfaces/Platform.interface'
import {toast} from 'react-toastify'
import ConnectPlatformStrip1 from './components/ConnectPlatformStrip1'
import ConnectedPlatformSettingModal1 from './components/ConnectedPlatformSettingModal1'
import {Link, Navigate, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import { isActiveBoolean } from 'src/redux/actions/changeisActiveAction'
import { getApproveSubscriptionAsync, getUserPlanAsync } from 'src/redux/actions/pricingActions'
import useAppToast from 'src/hooks/useAppToast'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'


const ConnectedPlatforms = () => {
  const navigate = useNavigate();
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false)
  const [showSettingModal, setShowSettingModal] = useState<boolean>(false)
  const [selectedPlatform, setSelectedPlatform] = useState<PlatformDataType>()
  const [selectedPlatformDelete, setSelectedPlatformDelete] = useState<PlatformDataType>()

  const [deletionCompleted, setDeletionCompleted] = useState<boolean>(false) // New state variable
  const {fetchedConnectedPlatformLoading} = useAppSelector((state) => state.fetchConnectedPlatform)
  const fetchedConnectedPlatformData = useAppSelector(
    (state) => state.fetchConnectedPlatform.fetchedConnectedPlatformData
    
  )
  const { userCurrentSubscription, requestSubscriptionLoading} = useAppSelector((state) => state.pricing)
  const {connectedPlatformData,platformSettingModalData } = useAppSelector((state) => state.connectedPlatform)
  const {successToast, errorToast} = useAppToast()
  const {selectedPlatformId} = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  const charge_id= searchParams.get("charge_id") 
  const [isVisibleAlertModel, setIsVisibleAlertModel] =useState<boolean>(false)


  useEffect(() => {

    if (charge_id && selectedPlatformId) {
      dispatch(getApproveSubscriptionAsync({platformId:Number(selectedPlatformId),chargeId: charge_id}))
      .then((response)=>{
        if (response?.payload?.status_code === 201 && response?.payload?.data) {
          successToast(response?.payload?.message)
   
       }else{
        errorToast(response?.payload?.massage)
       }
     
      })  // Dispatch the action with charge_id

      setIsVisibleAlertModel(true)
    }
  }, [charge_id])

  console.log('selectedPlatform', selectedPlatform)
  const dispatch = useAppDispatch()

  const onDeleteStripe = (platform: PlatformDataType) => {
    setSelectedPlatformDelete(platform)
    console.log('data platform to delete', platform)
    setSelectedPlatformDelete(platform)
    setShowAlertModal(true)
  }

  const onClickSetting = (platform: PlatformDataType) => {
    console.log('data platform', platform)
    setSelectedPlatform(platform)
    // {selectedPlatform &&
    //   (selectedPlatform.isMainStore === true ? (
        
    //     navigate(`/primary-platform-setting/${selectedPlatform.id}`)
     
    //   ):(
    //     navigate(`/platform-setting/${selectedPlatform.id}`)
    //   )
    // )}
     
    
   

    // setSelectedPlatform(platform)

    // setShowSettingModal(true)
    // dispatch(retrivePlatformDataAsync({id: platform?.id}))
    // console.log("fetched connected for editing:",fetchedConnectedPlatformData)
  }

  useEffect(() => {
    if (fetchedConnectedPlatformData && !fetchedConnectedPlatformLoading && deletionCompleted) {
      // Check if deletion is completed and loading is false
      dispatch(fetchConnectedPlatformAsync())
      setDeletionCompleted(false) // Reset deletionCompleted state
    }
  }, []) // Dependency array includes deletionCompleted

  useEffect(() => {
    dispatch(fetchConnectedPlatformAsync())
  }, [])

  useEffect(() => {
   if(platformSettingModalData){
    setSelectedPlatform(platformSettingModalData)
    setShowSettingModal(true)
   }
  }, [platformSettingModalData])

  useEffect(() => {
    dispatch(getUserPlanAsync()).then((response) => {
      console.log('list of  userplan', response)
    })
  }, [])
  
  // useEffect(() => {
  //   const modalState = sessionStorage.getItem('showSettingModal');
  //   if (modalState === 'true') {
  //     setShowSettingModal(true);
  //   } else {
  //     setShowSettingModal(false);
  //   }
  // }, []);

  // const showSuccessToast = () => {
  //   toast.success('Platform settings saved successfully!', {
  //     autoClose: 3000, // Set the duration for which the toast will be displayed
  //   })
  //   // Set showSettingModal to false after showing the toast
  // }

  const handleSaveSettings = async (allSettings: PlatformSettingsDataType) => {
    // try {
    //    await dispatch(createPlatformSettingAsync(allSettings)).then((response)=>{
    //     console.log("response in connected platform",response)
    //     if(response?.payload?.status_code === 200){
    //         // alert("connectedplatform toast")
    //         toast.success('Platform settings saved successfully !', {
    //         autoClose: 3000, // Set the duration for which the toast will be displayed
    //       })
    //     }
    //    })
    //   console.log('Platform settings created successfully!', allSettings)
    //   // showSuccessToast() // Show toast on successful save
    // } catch (error) {
    //   console.error('Error creating platform settings:', error)
    // }

    const id = selectedPlatform?.id
    const display_name = connectedPlatformData?.display_name
    const isActive = connectedPlatformData?.isActive == false ? true : true
    const payload = {
      display_name,
      isActive,
    }
    console.log('data in connectedplatform', {...payload})
    console.log("id  for now",id)
    await dispatch(isActiveBoolean({...payload, id}))


  }

  const onDeleteConfirmed = async () => {
    try {
      if (selectedPlatformDelete && selectedPlatformDelete.id !== undefined) {

        //uninstall shopify 
        if(selectedPlatformDelete?.code==="SHOPIFY"){
          dispatch(shopifyappUninstallAsync({id:selectedPlatformDelete?.id})).then((response)=>{
            toast.success(response?.payload?.massage) 
            dispatch(fetchConnectedPlatformAsync())
          })
        }
        
        dispatch(deletePlatformDataAsync({id: selectedPlatformDelete?.id})).then(() => {
          dispatch(fetchConnectedPlatformAsync())
        })
        toast.success('Platform deleted successfully!', {
          autoClose: 3000,
        })
        setDeletionCompleted(true) // Update deletionCompleted state
      } else {
        console.error('Platform id is undefined.')
      }
    } catch (error) {
      console.error('Error deleting platform:', error)
    } finally {
      setShowAlertModal(false)
    }
  }

  return (
    <>
      <div>
        <div id='test1' className='p-4 pt-0 rounded'>
          <div className='d-flex justify-content-end'>
            <Link to='/platforms' className='btn btn-primary btn-sm' style={{fontSize: '14px'}}>
              Add new channel
            </Link>
          </div>
         
        </div>
      </div>

      {fetchedConnectedPlatformLoading || requestSubscriptionLoading ? (
        <div className='d-flex align-items-center justify-content-center h-100'>
          <h1>
            <Spinner animation='border' variant='primary' />
          </h1>
        </div>
      ) : (
        <div className='py-4 pt-0'>
          {fetchedConnectedPlatformData?.length > 0 ?
            fetchedConnectedPlatformData.map((platform: any, index) => {
              if (platform.isMainStore === true) {
                return (
                  <ConnectPlatformStrip1
                    key={platform.id}
                    platform={platform}
                    onDelete={() => onDeleteStripe(platform)}
                    openSettingsModal={() => onClickSetting(platform)}
                  />
                )
              } else {
                return (
                  <ConnectPlatformStrip
                    key={platform.id}
                    platform={platform}
                    onDelete={() => onDeleteStripe(platform)}
                    openSettingsModal={() => onClickSetting(platform)}
                  />
                )
              }
            })
          : 
          <div className="alert bg-light-primary d-flex align-items-center p-5 shadow-sm">
            <span className="svg-icon svg-icon-2hx svg-icon-primary me-8">
              <KTSVG path="media/icons/duotune/communication/com009.svg" className="svg-icon-muted svg-icon-3hx" />
            </span>
          
            <div className="d-flex flex-column">
              <h5 className="mb-1">No Platform is connected yet </h5>
              <span>Please connect a platform. Click here to connect <Link to={'/dashboard'} > <KTSVG path="media/icons/duotune/general/gen016.svg" className="svg-icon-primary svg-icon-hx" /></Link>  </span>
            </div>
          </div>
          }
        </div>
      )}

      {selectedPlatform &&
        (selectedPlatform.isMainStore === true ? (
          <div>
            <ConnectedPlatformSettingModal1
              key={'setting modal1'}
              isVisible={showSettingModal}
              closeModal={() => setShowSettingModal(false)}
              selectedPlatformId={selectedPlatform?.id}
            />
          </div>
        ) : (
          <ConnectedPlatformSettingModal
            key={'setting modal'}
            isVisible={showSettingModal}
            closeModal={() => setShowSettingModal(false)}
            onSaveSettings={handleSaveSettings}
            selectedPlatformId={selectedPlatform?.id}
          />
        ))}

      <AlertModal
        isVisible={showAlertModal}
        closeModal={() => setShowAlertModal(false)}
        onDeleteConfirmed={onDeleteConfirmed}
        selectedPlatformDelete={selectedPlatformDelete}
      />

      <CommonAlertModal
          title = {userCurrentSubscription ? userCurrentSubscription.activated_on ? "You have successfully subscribed to the plan. You can now proceed further." : "Your request to change current plan is completed. we have received the payment, Your new plan is active." : "You have successfully subscribed to the plan. You can now proceed further." }
          subtitle=''
          onSuccess={() => {
            setIsVisibleAlertModel(false)
          }}
          onCancel={() => {
            setIsVisibleAlertModel(false)
          
          }}
          cancelBtnTitle='Close'
          isVisible={isVisibleAlertModel}
          isTermsVisible={false}
          setIsVisible={() => {}}
          successDisabled={true}
          showSaveButton={false}
        />

     
    </>
  )
}

export default ConnectedPlatforms
