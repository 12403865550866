import React, {useEffect, useState} from 'react'
import {fetchUserAccountDetailsAsync} from 'src/redux/actions/accountActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {USER_DATA} from '../_interfaces/auth.interface'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPen, faEyeSlash, faEye} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import {updateusernameAsync} from 'src/redux/actions/handelusernamechange'
import {updatepasswordAsync} from 'src/redux/actions/passwordAction'
import useAppToast from 'src/hooks/useAppToast'
import {Formik, Field, ErrorMessage} from 'formik'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {PasswordChangeType} from '../_interfaces/passwordChange.interface'
import {Button, Spinner} from 'react-bootstrap'
import {cancelSubscriptionAsync, getUserPlanAsync} from 'src/redux/actions/pricingActions'
import {UserSubscriptionPlan} from 'src/interfaces/Platform.interface'
import {format} from 'date-fns'

const initialValues = {
  previous_password: '',
  new_password: '',
  confirm_password: '',
}

const AccountSetting = () => {
  const dispatch = useAppDispatch()
  const {successToast, errorToast} = useAppToast()
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleShownamemodal = () => setShow1(!show1)
  const [passwordVisible, setPasswordVisible] = useState(false) // State to track password visibility
  const [passwordVisible1, setPasswordVisible1] = useState(false)
  const [passwordVisible2, setPasswordVisible2] = useState(false)
  const [prevPass, setPrevPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [confPass, setConfPass] = useState('')
  const {fetchedUserAccountLoading, fetchedUserAccountData} = useAppSelector(
    (state) => state.userAccountData
  )
  const [isSaving, setIsSaving] = useState(false) // State to track saving process
  const [userSubscribedPlan, setUserSubscriptionPlan] = useState<UserSubscriptionPlan>()
  const [userSubscribedPlanLoader, setUserSubscribedPlanLoader] = useState<boolean>(true)
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)

  const passwordSchema = Yup.object({
    previous_password: Yup.string().required(' enter your previous password'),
    new_password: Yup.string()
      .min(8)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,}$/,
        'Use 8 or more characters with at least one uppercase letter, one lowercase letter, one special character, and one number'
      )
      .required('Password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password')], 'Passwords must match')
      .required('Confirm password is required'),
  })

  const [userData, setUserData] = useState<USER_DATA>()
  const [name, setName] = useState<string>(userData?.name || '')
  useEffect(() => {
    // Dispatch the fetch user data action on component mount
    dispatch(fetchUserAccountDetailsAsync())
      .then((response) => {
        console.log('User account data:', response.payload)
        setUserData(response.payload) // Log the response data
      })
      .catch((error) => {
        console.error('Error fetching user data:', error)
      })

    dispatch(getUserPlanAsync()).then((response) => {
      console.log('list of  userplan', response)
      setUserSubscriptionPlan(response.payload)
      setUserSubscribedPlanLoader(false)
    })
  }, [])
  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }
  useEffect(() => {
    setName(userData?.name || '')
  }, [userData])

  const sendusername = () => {
    // Check if the name field is not empty
    if (name.trim() !== '') {
      const payload = {
        name,
      }
      dispatch(updateusernameAsync(payload)).then((response) => {
        successToast('Changes saved successfully')
      })
    } else {
      // Show an error toast or handle the case where the name field is empty
      errorToast('Name cannot be empty')
    }
  }

  // if (fetchedUserAccountLoading || !fetchedUserAccountData) {
  //   return <div>Loading...</div>; // Render a loading state if data is being fetched or if data is not available yet
  // }
  const buttonStyle = {
    // padding: '2px 2px 2px 2px',
    fontSize: '12px',
    paddingTop: '2px',
    paddingBottom: '2px',
  }

  const formattedValidDate = userSubscribedPlan?.valid_till
    ? format(new Date(userSubscribedPlan?.valid_till), ' dd-MM-yyyy , hh:mm a')
    : '' // Handle null or undefined values

  const formattedActivateDate = userSubscribedPlan?.activated_on
    ? format(new Date(userSubscribedPlan?.activated_on), ' dd-MM-yyyy , hh:mm a')
    : ''

  const {values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting} = useFormik(
    {
      initialValues,
      validationSchema: passwordSchema,
      onSubmit: (values, action) => {
        setIsSaving(true) // Set isSaving to true when form is being submitted
        console.log('submit', values)
        try {
          dispatch(updatepasswordAsync(values)).then((response) => {
            console.log('response on error password ', response)
            setIsSaving(false)
            if (response?.payload?.status_code === 200) {
              successToast(response?.payload?.massage)
              handleClose()
            } else {
              errorToast(
                'Previous Password is incorrect. Please try again with the actual Password.'
              )
            }
          })
        } catch (e) {
          setIsSaving(false)
          console.log('error', e)
        }
        action.resetForm()
      },
    }
  )

  return (
    <>
      <div className='card mb-5 '>
        <h3 className='fw-bolder m-0 p-4'>Profile Details</h3>
        <div className='border-bottom my-2 border-2'></div>
        {fetchedUserAccountLoading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{height: '50vh'}}
          >
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className='row mb-6 p-2 ms-2'>
              <label
                className='col-lg-4 col-form-label required fw-bold'
                style={{fontSize: '14px'}}
              >
                Full Name
              </label>
              <div className='col-lg-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Full Name'
                  name='fName'
                  value={name}
                  onChange={handleChangeName}
                />
              </div>
            </div>
            <div className='row mb-6 p-2 ms-2'>
              <label
                className='col-lg-4 col-form-label required fw-bold'
                style={{fontSize: '14px'}}
              >
                Email:
              </label>
              <div className='col-lg-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Email'
                  name='email'
                  value={userData?.email!}
                  readOnly
                />
              </div>
            </div>
            <div className='row mb-6 p-2 ms-2'>
              <label className='col-lg-4 col-form-label fw-bold' style={{fontSize: '14px'}}>
                <span className='required'>Password:</span>
              </label>
              <div className='col-lg-6 d-flex align-items-center'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-0 flex-grow-1'
                  placeholder='Password'
                  name='password'
                  value={'**********'}
                  readOnly
                />
                <button
                  className='btn  btn-primary  ms-3'
                  style={{whiteSpace: 'nowrap'}}
                  onClick={handleShow}
                >
                  Change Password
                </button>
              </div>
              <div className='border-bottom my-3 border-2'></div>
              <div className='d-flex justify-content-end py-6 px-2'>
                <button onClick={sendusername} type='submit' className='btn btn-primary me-2'>
                  Save Changes
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      <div className='card mb-5 '>
        <h3 className='fw-bolder m-0 p-4'>Subscription Details</h3>
        <div className='border-bottom my-2 border-2'></div>

        {userSubscribedPlanLoader ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{height: '50vh'}}
          >
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className='row mb-6 p-2 ms-2'>
              <label
                className='col-lg-4 col-form-label required fw-bold'
                style={{fontSize: '14px'}}
              >
                Current Plan
              </label>
              <div className='col-lg-6'>
                <span className=' form-control-lg mb-3 mb-lg-0'>
                  {userSubscribedPlan?.billing_plan?.display_name}{' '}
                  {userSubscribedPlan?.billing_plan?.price?.type == 'USD'
                    ? ' $ ' + userSubscribedPlan?.billing_plan?.price?.value
                    : ' ₹ ' + userSubscribedPlan?.billing_plan?.price?.value}
                </span>
              </div>
            </div>
            <div className='row mb-6 p-2 ms-2'>
              <label
                className='col-lg-4 col-form-label required fw-bold'
                style={{fontSize: '14px'}}
              >
                Subscription Status
              </label>
              <div className='col-lg-6'>
                {userSubscribedPlan?.isActive ? (
                  <span className='badge fs-5 badge-success ms-3'>Active</span>
                ) : (
                  <span className='badge badge-light-danger ms-3'>Deactive</span>
                )}
              </div>
            </div>
            {/* <div className='row mb-6 p-2 ms-2'>
              <label
                className='col-lg-4 col-form-label required fw-bold'
                style={{fontSize: '14px'}}
              >
                Amount
              </label>
              <div className='col-lg-6'>
                <input
                  readOnly
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder=''
                  // name='fName'
                  value={
                    userSubscribedPlan?.billing_plan?.price?.type == 'USD'
                      ? ' $ ' + userSubscribedPlan?.billing_plan?.price?.value
                      : ' ₹ ' + userSubscribedPlan?.billing_plan?.price?.value
                  }
                  // onChange={handleChangeName}
                />
              </div>
            </div> */}
            <div className='row mb-6 p-2 ms-2'>
              <label
                className='col-lg-4 col-form-label required fw-bold'
                style={{fontSize: '14px'}}
              >
                Subscribed Date
              </label>
              <div className='col-lg-6'>
                <span className=' form-control-lg mb-3 mb-lg-0'>{formattedActivateDate}</span>
              </div>
            </div>
            <div className='row mb-6 p-2 ms-2'>
              <label
                className='col-lg-4 col-form-label required fw-bold'
                style={{fontSize: '14px'}}
              >
                Next Payment Date
              </label>
              <div className='col-lg-6'>
                <span className=' form-control-lg mb-3 mb-lg-0'>{formattedValidDate}</span>
              </div>
            </div>
            {userSubscribedPlan?.isActive ? (
              <>
                <div className='border-bottom my-3 border-2'></div>
                <div className='d-flex justify-content-end py-6 px-2'>
                  <button 
                      onClick={() => {
                        dispatch(
                          cancelSubscriptionAsync({
                            subscription_id: userSubscribedPlan?.subscription_id,
                            platform_id: mainStoreData?.id,
                          })
                        ).then((response) => {
                          if (
                            response?.payload?.status_code === 201 &&
                            response?.payload?.data
                          ) {
                            successToast(response?.payload?.message)

                            dispatch(getUserPlanAsync()).then((response) => {
                              console.log('list of  userplan', response)
                              setUserSubscriptionPlan(response.payload)
                            })
                          } else {
                            errorToast(response?.payload?.message)
                            dispatch(getUserPlanAsync()).then((response) => {
                              console.log('list of  userplan', response)
                              setUserSubscriptionPlan(response.payload)
                            })
                          }
                        })
                      }}
                  type='submit' className='btn btn-danger me-2'>
                    Cancel Subscription
                  </button>
                </div>
              </>
            ) : null}
          </>
        )}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className='input-block'>
              <label
                htmlFor='previous_password'
                className='input-label col-lg-4 col-form-label required fw-bold'
              >
                Previous Password
              </label>
              <div className='d-flex'>
                <input
                  className={`form-control ${
                    touched.previous_password && errors.previous_password ? 'is-invalid' : ''
                  }`}
                  type={passwordVisible ? 'text' : 'password'}
                  autoComplete='off'
                  name='previous_password'
                  id='previous_password'
                  placeholder=' Enter Previous Password'
                  value={values.previous_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <button
                  className='btn btn-outline-secondary'
                  type='button'
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </button>
              </div>
              {errors.previous_password && touched.previous_password ? (
                <p className='form-error' style={{color: 'red'}}>
                  {errors.previous_password}
                </p>
              ) : null}
            </div>

            <div className='input-block'>
              <label
                htmlFor='new_password'
                className='input-label col-lg-4 col-form-label required fw-bold'
              >
                New Password
              </label>
              <div className='d-flex'>
                <input
                  className={`form-control ${
                    touched.new_password && errors.new_password ? 'is-invalid' : ''
                  }`}
                  type={passwordVisible1 ? 'text' : 'password'}
                  autoComplete='off'
                  name='new_password'
                  id='new_password'
                  placeholder=' Enter New Password'
                  value={values.new_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <button
                  className='btn btn-outline-secondary'
                  type='button'
                  onClick={() => setPasswordVisible1(!passwordVisible1)}
                >
                  {passwordVisible1 ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </button>
              </div>
              {errors.new_password && touched.new_password ? (
                <p className='form-error' style={{color: 'red'}}>
                  {errors.new_password}
                </p>
              ) : null}
            </div>

            <div className='input-block'>
              <label
                htmlFor='confirm_password'
                className='input-label col-lg-4 col-form-label required fw-bold'
              >
                Confirm Password
              </label>
              <div className='d-flex'>
                <input
                  className={`form-control ${
                    touched.confirm_password && errors.confirm_password ? 'is-invalid' : ''
                  }`}
                  type={passwordVisible2 ? 'text' : 'password'}
                  autoComplete='off'
                  name='confirm_password'
                  id='confirm_password'
                  placeholder=' Enter Confirm Password'
                  value={values.confirm_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <button
                  className='btn btn-outline-secondary'
                  type='button'
                  onClick={() => setPasswordVisible2(!passwordVisible2)}
                >
                  {passwordVisible2 ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </button>
              </div>
              {errors.confirm_password && touched.confirm_password ? (
                <p className='form-error' style={{color: 'red'}}>
                  {errors.confirm_password}
                </p>
              ) : null}
            </div>
            <div className='d-flex justify-content-end mt-2'>
              <Button className='me-3' onClick={handleClose}>
                Close
              </Button>
              <Button type='submit' disabled={isSubmitting}>
                {' '}
                {isSaving ? 'Saving' : 'Save'}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default AccountSetting
