import React, { FC, useState } from 'react'
import { ChartsWidget1, ChartsWidget2, ChartsWidget3, ChartsWidget4, ChartsWidget5, ChartsWidget6, ChartsWidget7, ChartsWidget8 } from 'src/marketConnect/partials/widgets'
import OrderReportChart from './OrderReportChart'
import { useAppSelector } from 'src/redux/storeHooks'
import { faBullhorn, faNewspaper } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Dashboard:FC = () => {
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const news = [
    { date: '1st Jan', title: 'News Title 1', description: 'News Description 1', link: 'https://example.com/news1' },
    { date: '2nd Jan', title: 'News Title 2', description: 'News Description 2', link: 'https://example.com/news2' },
    { date: '3rd Jan', title: 'News Title 3', description: 'News Description 3', link: 'https://example.com/news3' },
    { date: '4th Jan', title: 'News Title 4', description: 'News Description 4', link: 'https://example.com/news4' },
    { date: '5th Jan', title: 'News Title 5', description: 'News Description 5', link: 'https://example.com/news5' },
    { date: '6th Jan', title: 'News Title 6', description: 'News Description 6', link: 'https://example.com/news6' },
  ];

  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(news.length / itemsPerPage);

  const handleClickNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleClickPrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const currentNews = news.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div>
      <div className='row'>
        <div className='col-md-8 col-12'>
          {/* stat card section  */}
          <div className='row g-4'>
              <div className='col-lg-3 col-md-6 col-12 col-sm-6 '>
                <div className='card p-4 border-0 shadow-sm bg-hover-light-secondary '>
                  <p className='h5 mb-0 text-secondary'>Total SKU's</p>
                  <p className='fs-5 fw-bold mb-0'>9999</p>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12 col-sm-6 '>
                <div className='card p-4 border-0 shadow-sm bg-hover-light-secondary '>
                  <p className='h5 mb-0 text-secondary'>Primary Channel</p>
                  <div className='d-flex align-items-center'>
                  <img src={mainStoreData?.platform_info?.logo_ref} className='h-40px w-40px ms-sm-5 ms-md-2' alt='Logo' />
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12 col-sm-6 '>
                <div className='card p-4 border-0 shadow-sm bg-hover-light-secondary '>
                  <p className='h5 mb-0 text-secondary'>Synced SKU's</p>
                  <p className='fs-5 fw-bold mb-0'>9999</p>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12 col-sm-6 '>
                <div className='card p-4 border-0 shadow-sm bg-hover-light-secondary '>
                  <p className='h5 mb-0 text-secondary'>Order Sync Errors</p>
                  <p className='fs-5 fw-bold mb-0'>9999</p>
                </div>
              </div>
          </div>
          {/* stat card section  */}
          <div className="chart-section mt-4">
            <OrderReportChart className='' />
          </div>
        </div>
        {/* <div className='col-md-4 col-12'>
          <div className='card shadow border-0 mb-2' >
            <div className='card-header border-0 bg-white ribbon ribbon-start ribbon-clip'>
              <div className='ribbon-label'> <p className='fs-5 fw-bold mb-0' >News & Updates</p>
              <span className="ribbon-inner bg-primary"></span>
              </div>
            </div>
            <div className='card-body px-2' >
              <ol>
                {
                    [1,2,3,4,5].map((item)=>{
                        return <li key={'newsitem'+item} className='fs-5 my-2' >This is a sample update / news</li>
                    })
                }
              </ol>
            </div>
          </div> */}
       <div className='col-md-4 col-12'>
       <div className='card shadow border-0 mb-2'>
            <div className='card-header border-0 bg-white ribbon ribbon-start ribbon-clip'>
              <div className='ribbon-label'>
                <p className='fs-5 fw-bold mb-0'>News & Updates</p>
                <span className="ribbon-inner bg-primary"></span>
              </div>
            </div>
            <div className='card-body px-2'>
              <ol>
                {currentNews.map((item, index) => (
                  <li key={'newsitem' + index} className='fs-5 my-2'>
                    <div>
                      <strong>{item.date} <FontAwesomeIcon icon={faBullhorn} /><FontAwesomeIcon icon={faNewspaper} /></strong>
                      <br />
                      <span>{item.title}</span>
                      <br />
                      <a href={item.link} target='_blank' rel='noopener noreferrer' className='text-decoration-none text-dark'>
                        <span className='text-primary ms-2' onMouseEnter={(e) => e.currentTarget.textContent = 'Read More'} onMouseLeave={(e) => e.currentTarget.textContent = item.description}>
                          {item.description}
                        </span>
                      </a>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
            <div className='card-footer d-flex justify-content-between'>
              <button onClick={handleClickPrev} className='btn btn-primary' disabled={currentPage === 1}>Previous</button>
              <button onClick={handleClickNext} className='btn btn-primary' disabled={currentPage === totalPages}>Next</button>
            </div>
          </div>
          <div className='card shadow border-0 mb-2' >
            <div className='card-header border-0 bg-white ribbon ribbon-start ribbon-clip'>
              <div className='ribbon-label'> <p className='fs-5 fw-bold mb-0' >Setup Guide</p>
              <span className="ribbon-inner bg-primary"></span>
              </div>
            </div>
            <div className='card-body px-2' >
              <ol>
                {
                    [1,2,3,4,5].map((item)=>{
                        return <li key={'newsitem'+item} className='fs-5 my-2' >This is a sample update / news</li>
                    })
                }
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
