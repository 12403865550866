import React, {useEffect, useRef, useState} from 'react'
import {responsivePropType} from 'react-bootstrap/esm/createUtilityClasses'
import {useParams} from 'react-router-dom'
import useAppToast from 'src/hooks/useAppToast'
import {UserSubscriptionPlan} from 'src/interfaces/Platform.interface'
import {KTSVG} from 'src/marketConnect/helpers'
import {ILayout, useLayout} from 'src/marketConnect/layout/core'
import {
  cancelSubscriptionAsync,
  getAllPlansAsync,
  getUserPlanAsync,
  requestSubscriptionAsync,
  updateSubscriptionAsync,
} from 'src/redux/actions/pricingActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'

const Pricing = () => {
  const [billingPeriod, setBillingPeriod] = useState('month')
  const [selectedPlanId, setSelectedPlanId] = useState(null)
  const [listOfPlans, setListOfPlans] = useState<any[]>([])
  const [userSubscribedPlan, setUserSubscriptionPlan] = useState<UserSubscriptionPlan>()
  const {platformId} = useParams()
  const dispatch = useAppDispatch()
  const {successToast, warningToast, errorToast} = useAppToast()
  const {config, setLayout} = useLayout()

  const {changeSubscriptionLoading, cancelSubscriptionLoading, requestSubscriptionLoading} =
    useAppSelector((state) => state.pricing)

  const handleBillingPeriodChange = (period) => {
    setBillingPeriod(period)
    setSelectedPlanId(null) // Reset selected plan when billing period changes
  }

  const handlePlanSelect = (planId) => {
    setSelectedPlanId(planId)
  }

  useEffect(() => {
    dispatch(getAllPlansAsync()).then((response) => {
      if (Array.isArray(response.payload)) {
        setListOfPlans(response.payload)
      } else {
        console.error('Expected an array for listOfPlans:', response.payload)
      }
    })
    dispatch(getUserPlanAsync()).then((response) => {
      console.log('list of  userplan', response)
      setUserSubscriptionPlan(response.payload)
    })
  }, [])

  const plans = [
    {
      id: 1,
      price: {
        type: 'PRICE',
        value: 0.0,
        currency_iso: 'INR',
      },
      plan_name: 'Basic',
      plan_id: 'basic_001',
      display_name: 'Starter - Basic plan',
      platform_limit: 1,
      product_limit: 100,
      order_limit: 100,
      isDynamicFieldMappingAllowed: false,
      isDiscogsAutoRelistingAllowed: false,
      isAutoRepricingAllowed: false,
      isBatchUpdatesAllowed: false,
      isTechSupportAllowed: false,
      isDedicatedAccountManagerAllowed:false,
      isLiveChatSupportAllowed:false,
      isEmailSupportAllowed:true,
      isNearRealtimeSyncAllowed:true,
      isUnlimitedChannelIntegrationAllowed:false,
      isInventorySyncAllowed:false,
      isProductListingsAllowed:false,
      description: 'This is a description',
      trialPeriodDays: 7,
      billing_frequency: 'FREE_FOREVER',
      created_at: '2024-04-02T14:28:08Z',
      updated_at: '2024-04-02T14:28:08Z',
      showcase_price: 1,
    },
    {
      id: 2,
      price: {
        type: 'PRICE',
        value: 249.0,
        currency_iso: 'INR',
      },
      plan_name: 'Standard',
      plan_id: 'standard_001',
      display_name: 'Standard plan',
      platform_limit: 10,
      product_limit: 1000,
      order_limit: 1000,
      isDynamicFieldMappingAllowed: false,
      isDiscogsAutoRelistingAllowed: false,
      isAutoRepricingAllowed: true,
      isBatchUpdatesAllowed: true,
      isTechSupportAllowed: true,
      isDedicatedAccountManagerAllowed:true,
      isLiveChatSupportAllowed:true,
      isEmailSupportAllowed:true,
      isNearRealtimeSyncAllowed:false,
      isUnlimitedChannelIntegrationAllowed:true,
      isInventorySyncAllowed:false,
      isProductListingsAllowed:false,
      description: 'This is a description',
      trialPeriodDays: 7,
      billing_frequency: 'EVERY_30_DAYS',
      created_at: '2024-04-02T14:28:08Z',
      updated_at: '2024-04-02T14:28:08Z',
      showcase_price: 2,
    },
    {
      id: 3,
      price: {
        type: 'PRICE',
        value: 450.0,
        currency_iso: 'INR',
      },
      plan_name: 'Premium',
      plan_id: 'premium_001',
      display_name: 'Premium plan',
      platform_limit: 20,
      product_limit: 2000,
      order_limit: 2000,
      isDynamicFieldMappingAllowed: true,
      isDiscogsAutoRelistingAllowed: true,
      isAutoRepricingAllowed: true,
      isBatchUpdatesAllowed: true,
      isTechSupportAllowed: true,
      isDedicatedAccountManagerAllowed:true,
      isLiveChatSupportAllowed:true,
      isEmailSupportAllowed:true,
      isNearRealtimeSyncAllowed:true,
      isUnlimitedChannelIntegrationAllowed:true,
      isInventorySyncAllowed:true,
      isProductListingsAllowed:true,
      description: 'This is a description',
      trialPeriodDays: 7,
      billing_frequency: 'EVERY_30_DAYS',
      created_at: '2024-04-02T14:28:08Z',
      updated_at: '2024-04-02T14:28:08Z',
      showcase_price: 3,
    },
    {
      id: 4,
      price: {
        type: 'PRICE',
        value: 2988.0,
        currency_iso: 'INR',
      },
      plan_name: 'Standard Annual',
      plan_id: 'standard_012',
      display_name: 'Standard plan',
      platform_limit: 30,
      product_limit: 10000,
      order_limit: 10000,
      isDynamicFieldMappingAllowed: false,
      isDiscogsAutoRelistingAllowed: false,
      isAutoRepricingAllowed: true,
      isBatchUpdatesAllowed: true,
      isTechSupportAllowed: true,
      isDedicatedAccountManagerAllowed:true,
      isLiveChatSupportAllowed:false,
      isEmailSupportAllowed:false,
      isNearRealtimeSyncAllowed:false,
      isUnlimitedChannelIntegrationAllowed:false,
      isInventorySyncAllowed:true,
      isProductListingsAllowed:true,
      description: 'This is a description',
      trialPeriodDays: 7,
      billing_frequency: 'ANNUAL',
      created_at: '2024-04-02T14:28:08Z',
      updated_at: '2024-04-02T14:28:08Z',
      showcase_price: 4,
    },
    {
      id: 5,
      price: {
        type: 'PRICE',
        value: 5400.0,
        currency_iso: 'INR',
      },
      plan_name: 'Premium Annual',
      plan_id: 'premium_012',
      display_name: 'Premium plan',
      platform_limit: 50,
      product_limit: 20000,
      order_limit: 20000,
      isDynamicFieldMappingAllowed: true,
      isDiscogsAutoRelistingAllowed: true,
      isAutoRepricingAllowed: true,
      isBatchUpdatesAllowed: true,
      isTechSupportAllowed: true,
      isDedicatedAccountManagerAllowed:true,
      isLiveChatSupportAllowed:true,   
      isEmailSupportAllowed:true,
      isNearRealtimeSyncAllowed:true,
      isUnlimitedChannelIntegrationAllowed:true,
      isInventorySyncAllowed:true,
      isProductListingsAllowed:true,
      description: 'This is a description',
      trialPeriodDays: 7,
      billing_frequency: 'ANNUAL',
      created_at: '2024-04-02T14:28:08Z',
      updated_at: '2024-04-02T14:28:08Z',
      showcase_price: 5,
    },
  ]

  const filteredPlans =
    billingPeriod === 'annual'
      ? listOfPlans.filter(
          (plan) => plan.billing_frequency === 'ANNUAL' || plan.billing_frequency === 'FREE_FOREVER'
        )
      : listOfPlans.filter(
          (plan) =>
            plan.billing_frequency === 'EVERY_30_DAYS' || plan.billing_frequency === 'FREE_FOREVER'
        )

  // const userSubscribedPlan = {
  //   billing_plan: {
  //     plan_name: 'Basic',
  //     plan_id: 'basic_001',
  //     platform_limit: 1,
  //     product_limit: 100,
  //     display_name: 'Starter - Basic free plan',
  //     description: 'This is a description',
  //     billing_frequency: 'FREE_FOREVER',
  //     price: {
  //       type: 'PRICE',
  //       value: 0.0,
  //       currency_iso: 'INR',
  //     },
  //     showcase_price: {
  //       type: 'PRICE',
  //       value: 0.0,
  //       currency_iso: 'INR',
  //     },
  //     created_at: '2024-04-02T14:28:08Z',
  //     updated_at: '2024-04-02T14:28:08Z',
  //   },
  //   isActive: true,
  //   valid_till: null,
  //   activated_on: null,
  //   subscription_id: '',
  //   user_id: 1,
  // }

  const calculatePrice = (plan) => {
    if (plan.billing_frequency === 'ANNUAL') {
      const discount = 0.2 // 20% discount
      const discountedPrice = plan.price.value * (1 - discount)
      return discountedPrice // Format to two decimal places
    } else {
      return plan.price.value
    }
  }

  const planCardStyle = (isSelected: boolean): React.CSSProperties => ({
    minHeight: '300px',
    maxWidth: '350px',
    minWidth: '325px',

    // Set a fixed width
    flexWrap: 'wrap',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: isSelected ? '#f0f8ff' : '#f9f9f9',
    border: isSelected ? '2px solid #007bff' : '1px solid #ddd',
    zIndex: isSelected ? 10 : 1,
    position: 'relative', // Ensure z-index works properly
  })

  // useEffect(() => {
  //  setLayout((pre:ILayout)=>{
  //   return {...pre, {pre?.app}}
  //  }),

  // }, [config])

  return (
    <div>
      <div className='d-flex flex-column '>
        <div id='kt_app_toolbar' className='app-toolbar py-0 py-lg-0'>
          <div id='kt_app_toolbar_container' className='app-container d-flex '>
            <div className='page-title '>
              <h1 className='page-heading d-flex text-gray fw-bold fs-3 flex-column justify-content-center my-0'>
                Pricing
              </h1>
            </div>
          </div>
        </div>
        <div id='kt_app_content' className='app-content mt-0'>
          <div id='kt_app_content_container' className='app-container px-sm-0'>
            <div className='card' id='kt_pricing'>
              <div className='card-body p-lg-4'>
                <div className='d-flex flex-column'>
                  <div className='text-center'>
                    <h1 className='fs-2 mb-2'>Choose Your Plan</h1>
                  </div>
                  <div
                    className='nav-group nav-group-outline mx-auto mb-5 mt-3'
                    data-kt-buttons='true'
                    data-kt-initialized='1'
                  >
                    <button
                      className={`btn btn-sm  btn-active me-2 ${
                        billingPeriod === 'month' ? 'active' : ''
                      }`}
                      data-kt-plan='month'
                      data-fdprocessedid='ntglqa'
                      onClick={() => handleBillingPeriodChange('month')}
                      style={{
                        backgroundColor: billingPeriod === 'month' ? '#D3D3D3' : 'transparent', // Light gray color if active
                        borderColor: billingPeriod === 'month' ? '#D3D3D3' : '#ddd', // Light gray color if active
                        color: billingPeriod === 'month' ? '#000' : '#000', // Text color
                      }}
                    >
                      Monthly
                    </button>
                    <button
                      className={`btn btn-sm btn-color-gray-600 btn-active  ${
                        billingPeriod === 'annual' ? 'active' : ''
                      }`}
                      data-kt-plan='annual'
                      data-fdprocessedid='g5m52i'
                      onClick={() => handleBillingPeriodChange('annual')}
                      style={{
                        backgroundColor: billingPeriod === 'annual' ? '#D3D3D3' : 'transparent', // Light gray color if active
                        borderColor: billingPeriod === 'annual' ? '#D3D3D3' : '#ddd', // Light gray color if active
                        color: billingPeriod === 'annual' ? '#000' : '#000', // Text color
                      }}
                    >
                      Annually 20% Off
                    </button>
                  </div>
                  <div className='row g-10 overflow-x-scroll flex-nowrap justify-content-around'>
                    {filteredPlans?.length &&
                      filteredPlans?.map((plan) => (
                        <div
                          className='col-lg-3 col-md-6 col-12 mb-1'
                          style={{minHeight: '300px', minWidth: '355px'}}
                          key={plan.id}
                        >
                          <div className='d-flex h-100'>
                            <div
                              // style={planCardStyle(selectedPlanId === plan.plan_id)}
                              className={
                                selectedPlanId === plan.plan_id
                                  ? 'w-100 d-flex flex-column  shadow rounded-3 bg-light bg-opacity-105 p-4 border border-4 border-primary z-index-10'
                                  : 'w-100 d-flex flex-column  shadow rounded-3 bg-light bg-opacity-105 p-4'
                              }
                              onClick={() => handlePlanSelect(plan.plan_id)} // Handle plan selection on click
                            >
                              <div className='mb-2 text-center w-100 '>
                                <h1 className=' fs-4   text-gray-900 mb-2 fw-bolder p-2 text-truncate'>
                                  {plan.display_name}
                                </h1>
                              </div>
                              <div className='mb-2 text-center'>
                                <div className='text-center'>
                                  <span className=' text-primary'>
                                    {plan?.price?.value > 0
                                      ? plan?.price?.currency_iso === 'USD'
                                        ? ' $ '
                                        : ' ₹ '
                                      : " "}
                                  </span>
                                  <span className='fw-bold text-primary' style={{fontSize: '40px'}}>
                                    {plan?.price?.value > 0 ? calculatePrice(plan) : 'Free'}
                                  </span>
                                  <span className='fs-7 fw-semibold opacity-50'>
                                    {plan?.price?.value > 0
                                      ? billingPeriod === 'annual'
                                        ? '/Year'
                                        : '/Month'
                                      : null}
                                  </span>
                                </div>
                              </div>
                              <div className=' my-3'>
                                {/* <div className='d-flex  mb-1'>
                                  <div className='fw-semibold fs-6 text-gray-800 flex '>
                                    <div>Upto {plan?.order_limit} Orders/Month</div>
                                    <div>Manage upto {plan?.product_limit} Products</div>
                                  </div>
                                </div> */}
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                     {plan?.order_limit} Orders/Month
                                  </span>
                                  <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    {plan?.product_limit} SKU
                                  </span>
                                  <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                </div>
                                {/* <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    Dynamic Field Mapping
                                  </span>
                                  {plan?.isDynamicFieldMappingAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    Discogs Auto Relisting
                                  </span>
                                  {plan?.isDiscogsAutoRelistingAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    Auto Repricing
                                  </span>
                                  {plan?.isAutoRepricingAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    Batch Updates
                                  </span>
                                  {plan?.isBatchUpdatesAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div> */}
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                  Unlimited Channel Integration
                                  </span>
                                  {plan?.isUnlimitedChannelIntegrationAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                  Inventory Sync
                                  </span>
                                  {plan?.isInventorySyncAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                  Product Listings
                                  </span>
                                  {plan?.isProductListingsAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                  Dedicated Account Manager
                                  </span>
                                  {plan?.isDedicatedAccountManagerAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                  Live Chat Support
                                  </span>
                                  {plan?.isLiveChatSupportAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    Email Support
                                  </span>
                                  {plan?.isEmailSupportAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                  Near Realtime Sync
                                  </span>
                                  {plan?.isNearRealtimeSyncAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                              </div>

                              <div className='d-flex flex-wrap  justify-content-center  flex-column h-100 align-items-center'>
                                {userSubscribedPlan?.billing_plan?.plan_id === plan.plan_id ? (
                                    <div className='d-flex align-items-center me-2' style={{justifyContent: 'center'}}>
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen026.svg'
                                      className='svg-icon-success svg-icon-2hx me-1'
                                    />
                                    <h4 style={{fontWeight: 'bold', fontStyle: 'italic', margin: 0}}>
                                      Subscribed
                                    </h4>
                                  </div>
                                ) : plan?.price?.value >
                                  Number(userSubscribedPlan?.billing_plan?.price?.value) ? (
                                  <button
                                    className='btn btn-sm btn-primary'
                                    style={{backgroundColor: 'green'}}
                                    onClick={() => {
                                      if (
                                        userSubscribedPlan?.subscription_id === '' ||
                                        userSubscribedPlan?.subscription_id === '\n'
                                      ) {
                                        //if user is upgrading first time
                                        if (
                                          userSubscribedPlan?.activated_on == null &&
                                          userSubscribedPlan?.valid_till == null
                                        ) {
                                          dispatch(
                                            requestSubscriptionAsync({
                                              plan_id: plan.id,
                                              platform_id: platformId,
                                              take_free_days_trial: 0,
                                            })
                                          )
                                            .unwrap()
                                            .then((response) => {
                                              // Check if the response is successful
                                              if (response.status_code === 201 && response.data) {
                                                // Automatically open the link in a new tab
                                                successToast(response?.massage)
                                                window.open(response.data, '')
                                              } else {
                                                // Handle error or different status codes
                                                console.error('Error response:', response)
                                                // Optionally, show a user-friendly message or perform other actions
                                              }
                                            })
                                            .catch((error) => {
                                              // Handle any errors that occur during dispatch or response handling
                                              console.error(
                                                'Error during subscription request:',
                                                error
                                              )
                                              // Optionally, show a user-friendly message or perform other actions
                                            })
                                        } else {
                                          // Dispatch requestSubscriptionAsync if no subscription_id
                                          dispatch(
                                            requestSubscriptionAsync({
                                              plan_id: plan.id,
                                              platform_id: platformId,
                                              take_free_days_trial: plan.trialPeriodDays,
                                            })
                                          )
                                            .unwrap()
                                            .then((response) => {
                                              // Check if the response is successful
                                              if (response.status_code === 201 && response.data) {
                                                // Automatically open the link in a new tab
                                                successToast(response?.massage)
                                                window.open(response.data, '')
                                              } else {
                                                // Handle error or different status codes
                                                console.error('Error response:', response)
                                                // Optionally, show a user-friendly message or perform other actions
                                              }
                                            })
                                            .catch((error) => {
                                              // Handle any errors that occur during dispatch or response handling
                                              console.error(
                                                'Error during subscription request:',
                                                error
                                              )
                                              // Optionally, show a user-friendly message or perform other actions
                                            })
                                        }
                                      } else {
                                        // Dispatch updateSubscriptionAsync if subscription_id is present
                                        dispatch(
                                          updateSubscriptionAsync({
                                            plan_id: plan?.id,
                                            platform_id: Number(platformId),
                                            subscription_id: userSubscribedPlan?.subscription_id,
                                          })
                                        )
                                          .unwrap()
                                          .then((response) => {
                                            // Check if the response is successful
                                            if (response?.status_code === 201 && response?.data) {
                                              successToast(response?.massage)
                                              // Automatically open the link in a new tab
                                              window.open(response.data, '_blank')
                                            } else {
                                              // Handle error or different status codes

                                              errorToast(response?.error)
                                              console.error('Error response:', response)
                                              // Optionally, show a user-friendly message or perform other actions
                                            }
                                          })
                                          .catch((error) => {
                                            // Handle any errors that occur during dispatch or response handling
                                            console.error(
                                              'Error during subscription update:',
                                              error
                                            )
                                            // Optionally, show a user-friendly message or perform other actions
                                          })
                                      }
                                    }}
                                  >
                                    Upgrade
                                  </button>
                                ) : (
                                  <button
                                    className='btn btn-sm btn-primary m-2'
                                    style={{backgroundColor: 'green'}}
                                    onClick={() => {
                                      if (
                                        userSubscribedPlan?.subscription_id === '' ||
                                        userSubscribedPlan?.subscription_id === '\n'
                                      ) {
                                        // Dispatch requestSubscriptionAsync if no subscription_id
                                        dispatch(
                                          requestSubscriptionAsync({
                                            plan_id: plan.id,
                                            platform_id: platformId,
                                            take_free_days_trial: plan.trialPeriodDays,
                                          })
                                        )
                                          .unwrap()
                                          .then((response) => {
                                            // Check if the response is successful
                                            if (response.status_code === 201 && response.data) {
                                              // Automatically open the link in a new tab
                                              successToast(response?.massage)
                                              window.open(response.data, '')
                                            } else {
                                              // Handle error or different status codes
                                              console.error('Error response:', response)
                                              // Optionally, show a user-friendly message or perform other actions
                                            }
                                          })
                                          .catch((error) => {
                                            // Handle any errors that occur during dispatch or response handling
                                            console.error(
                                              'Error during subscription request:',
                                              error
                                            )
                                            // Optionally, show a user-friendly message or perform other actions
                                          })
                                      } else {
                                        // Dispatch updateSubscriptionAsync if subscription_id is present
                                        dispatch(
                                          updateSubscriptionAsync({
                                            plan_id: plan?.id,
                                            platform_id: Number(platformId),
                                            subscription_id: userSubscribedPlan?.subscription_id,
                                          })
                                        )
                                          .unwrap()
                                          .then((response) => {
                                            // Check if the response is successful
                                            if (response.status_code === 201 && response.data) {
                                              // Automatically open the link in a new tab
                                              window.open(response.data, '_blank')
                                            } else {
                                              // Handle error or different status codes
                                              console.error('Error response:', response)
                                              // Optionally, show a user-friendly message or perform other actions
                                            }
                                          })
                                          .catch((error) => {
                                            // Handle any errors that occur during dispatch or response handling
                                            console.error(
                                              'Error during subscription update:',
                                              error
                                            )
                                            // Optionally, show a user-friendly message or perform other actions
                                          })
                                      }
                                    }}
                                  >
                                    Downgrade
                                  </button>
                                )}

                                {userSubscribedPlan?.billing_plan?.plan_id === plan?.plan_id &&
                                userSubscribedPlan?.isActive
                                  ? plan.trialPeriodDays > 0 && (
                                    <>
                                      <button
                                        className='btn btn-sm btn-danger m-2'
                                        onClick={() => {
                                          dispatch(
                                            cancelSubscriptionAsync({
                                              subscription_id: userSubscribedPlan?.subscription_id,
                                              platform_id: platformId,
                                            })
                                          ).then((response) => {
                                            if (
                                              response?.payload?.status_code === 201 &&
                                              response?.payload?.data
                                            ) {
                                              successToast(response?.payload?.message)

                                              dispatch(getUserPlanAsync()).then((response) => {
                                                console.log('list of  userplan', response)
                                                setUserSubscriptionPlan(response.payload)
                                              })
                                            } else {
                                              errorToast(response?.payload?.message)
                                              dispatch(getUserPlanAsync()).then((response) => {
                                                console.log('list of  userplan', response)
                                                setUserSubscriptionPlan(response.payload)
                                              })
                                            }
                                          })
                                        }}
                                      >
                                        Cancel Subscription
                                      </button></>
                                    
                                    )
                                  : plan.trialPeriodDays > 0 &&                                    
                                    userSubscribedPlan?.billing_plan.billing_frequency == "FREE_FOREVER" && userSubscribedPlan?.activated_on && (
                                      <button
                                        className='btn btn-sm btn-primary m-2'
                                        style={{backgroundColor: 'orange'}}
                                        onClick={() => {
                                          dispatch(
                                            requestSubscriptionAsync({
                                              plan_id: plan.id,
                                              platform_id: platformId,
                                              take_free_days_trial: 10,
                                            })
                                          )
                                            .unwrap()
                                            .then((response) => {
                                              // Check if the response is successful
                                              if (response.status_code === 201 && response.data) {
                                                // Automatically open the link in a new tab
                                                window.open(response.data, '_blank')
                                              } else {
                                                // Handle error or different status codes
                                                console.error('Error response:', response)
                                                // Optionally, show a user-friendly message or perform other actions
                                              }
                                            })
                                            .catch((error) => {
                                              // Handle any errors that occur during dispatch or response handling
                                              console.error(
                                                'Error during subscription request:',
                                                error
                                              )
                                              // Optionally, show a user-friendly message or perform other actions
                                            })
                                        }}
                                      >
                                        Start {plan?.trialPeriodDays}-Day Free Trial
                                      </button>
                                    )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pricing
